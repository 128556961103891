<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <i class="fas fa-bars"> </i>
    </v-btn>

    <v-toolbar-title
      style="padding-right: 50px"
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />
    <v-btn href="/main" class="primary">
      {{ $t("back to website") }}
    </v-btn>
    <!-- <v-text-field
      :label="$t('search')"
      color="secondary"
      hide-details
      style="max-width: 165px;"
    >
      <template
        v-if="$vuetify.breakpoint.mdAndUp"
        v-slot:append-outer
      >
        <v-btn
          class="mt-n2"
          elevation="1"
          fab
          small
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field> -->

    <div class="mx-3" />
<!-- 
    <v-btn class="ml-2" min-width="0" text to="/">
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn> -->

    <!-- <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            color="red"
            overlap
            bordered
          >
            <template v-slot:badge>
              <span>5</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div>
          <app-bar-item
            v-for="(n, i) in notifications"
            :key="`item-${i}`"
          >
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu> -->

    <!-- <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/pages/user"
    >
      <v-icon>mdi-account</v-icon>
    </v-btn> -->
    <v-row justify="start" style="max-width: 80px; margin: -20px 0px 0px 20px">
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-col>
        <v-btn
          style="font-size: 17px"
          fixed
          height="30"
          width="30"
          @click="changeLocaleToEnglish('en')"
          icon
        >
          <!-- <flag style="font-size: 60px;border-radius:2em;" iso="gb" /> -->
          en
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn
          style="font-size: 17px"
          fixed
          width="30"
          height="30"
          @click="changeLocaleToArabic('ar')"
          icon
        >
          <!-- <flag style="font-size: 60px ;border-radius:2em;" iso="sa" /> -->
          ar
        </v-btn>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations } from "vuex";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    rtl: true,
    notifications: [
      "Mike John Responded to your email",
      "You have 5 new tasks",
      "You're now friends with Andrew",
      "Another Notification",
      "Another one",
    ],
  }),

  computed: {
    ...mapState(["drawer"]),
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    changeLocaleToArabic(val) {
      this.rtl = true;
      this.$store.dispatch("locales/change", val);
      this.$i18n.locale = val;
      localStorage.setItem("locale", val);
       window.location.reload();
    },
    changeLocaleToEnglish(val) {
      this.rtl = false;
      this.$store.dispatch("locales/change", val);
      this.$i18n.locale = val;
      localStorage.setItem("locale", val);
       window.location.reload();
    },
  },
  mounted() {
     if(this.locale == "ar"){
    document.title = 'ركن الفخامة | ' + this.$t(this.$route.name==null?'Home':this.$route.name,'ar');
    }else
    document.title = 'ruknalfakhamah | ' + this.$t(this.$route.name==null?'Home':this.$route.name,'en');

  },
  watch: {
    rtl: {
      handler(newval, oldval) {
        this.$vuetify.rtl = newval;
        localStorage.setItem("rtl", newval);
      },
    },
  },
};
</script>
<style>
.v-progress-circular__overlay {
    color: #2b942f;
}
.theme--light.v-label {
    color: rgba(0, 0, 0, 0.774)!important;
}
</style>
